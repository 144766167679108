.cur-click{
    cursor: pointer;
}

.menu-close{
    height: 0;
}

.menu-open{
    height: auto;
}